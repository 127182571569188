import DashboardLayout from '../layout/DashboardLayout.vue'
import Login from '../layout/Login.vue'
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue'

// Admin pages
import EditAdmRegister from 'src/pages/EditAdmRegister.vue'
import MinhaConta from 'src/pages/MinhaConta.vue'
import UserRegister from 'src/pages/UserRegister.vue'
import ListClassificacao from 'src/pages/cad/classificacao/ListClassificacao.vue'
import ListEmpresaPJ from 'src/pages/cad/empresa-pj/ListEmpresaPJ.vue'
import ListEmpresa from 'src/pages/cad/empresa/ListEmpresa.vue'
import ListGrupoUsuario from 'src/pages/cad/grupo-usuario/ListGrupoUsuario.vue'
import ListLocal from 'src/pages/cad/local/ListLocal.vue'
import ListProfissional from 'src/pages/cad/profissional/ListProfissional.vue'
import ListSituacao from 'src/pages/cad/situacao/ListSituacao.vue'
import ListTipo from 'src/pages/cad/tipo/ListTipo.vue'
import ListUsuario from 'src/pages/cad/usuario/ListUsuario.vue'

import Agenda from 'src/pages/Agenda.vue'
import Dashboard from 'src/pages/Dashboard.vue'
import Denied from 'src/pages/Denied.vue'
import Fechamentos from 'src/pages/Fechamentos.vue'
import Home from 'src/pages/Home.vue'
import Icons from 'src/pages/Icons.vue'
import ManutencaoAgenda from 'src/pages/ManutencaoAgenda.vue'
import Maps from 'src/pages/Maps.vue'
import Mensagens from 'src/pages/Mensagens.vue'
import Notifications from 'src/pages/Notifications.vue'
import Relatorio from 'src/pages/Relatorio.vue'
import RelatorioFinanceiro from 'src/pages/RelatorioFinanceiro.vue'
import RelatorioTerceiro from 'src/pages/RelatorioTerceiro.vue'
import Relatorios from 'src/pages/Relatorios.vue'
import Suporte from 'src/pages/Suporte.vue'
import Teste from 'src/pages/Teste.vue'
import TesteAgenda from 'src/pages/TesteAgenda.vue'
import TesteWebSocket from 'src/pages/TesteWebSocket.vue'
import Upgrade from 'src/pages/Upgrade.vue'
import Escala from 'src/pages/escala/ListEscala.vue'
import PoliticaPrivacidade from 'src/pages/termos/PoliticaPrivacidade.vue'
import TermoUso from 'src/pages/termos/TermoUso.vue'

import Form from 'src/pages/form/Form.vue'
import FormCadastroSucesso from 'src/pages/form/FormCadastroSucesso.vue'
import FormCadastro from 'src/pages/form/forms/FormCadastro.vue'

// Credenciamento
import MuralVaga from 'src/pages/MuralVaga.vue'
import Vaga from 'src/pages/Vaga.vue'
import DashCredenciamento from 'src/pages/credenciamento/DashCredenciamento.vue'
import ListCredenciamento from 'src/pages/credenciamento/ListCredenciamento.vue'
import PosCriarConta from 'src/pages/credenciamento/PosCriarConta.vue'
import PosEnviarCredenciamento from 'src/pages/credenciamento/PosEnviarCredenciamento.vue'
import RelatoriosCredenciamento from 'src/pages/credenciamento/RelatoriosCredenciamento.vue'
import ListCredenciamentoPF from 'src/pages/credenciamentoPF/ListCredenciamentoPF.vue'
import ListCredenciamentoPJ from 'src/pages/credenciamentoPJ/ListCredenciamentoPJ.vue'
import CriarConta from 'src/pages/form/CriarConta.vue'
import Credenciamento from 'src/pages/form/credenciamento/Credenciamento.vue'
import Credenciamentos from 'src/pages/form/credenciamento/Credenciamentos.vue'

import ListPagamento from 'src/pages/pagamento/ListPagamento.vue'

//Especialização
//import Especializacao from 'src/pages/form/especializacao/Especializacao.vue'

//Candidatos
import ListCandidato from 'src/pages/candidatos/ListCandidato.vue'
import ListCandidatura from 'src/pages/candidatos/ListCandidatura.vue'

import Aprovacao from 'src/pages/aprovacao/Aprovacao.vue'
import ListAprovacao from 'src/pages/aprovacao/ListAprovacao.vue'

// Login pages
import LoginAcesso from 'src/pages/login/LoginAcesso.vue'
import RecuperarSenha from 'src/pages/login/RecuperarSenha.vue'
import ResetarSenha from 'src/pages/login/ResetarSenha.vue'

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/login/acesso',
    children: [
      {
        path: '/muralVaga',
        name: 'MuralVagaGeral',
        component: MuralVaga,
        meta: { permissions: ['LOGOFF'] }
      },
      {
        path: '/termouso/:identificadorEmpresa',
        name: 'TermoUso',
        component: TermoUso,
        meta: { permissions: ['LOGOFF'] }
      },
      {
        path: '/politicaprivacidade/:identificadorEmpresa',
        name: 'PoliticaPrivacidade',
        component: PoliticaPrivacidade,
        meta: { permissions: ['LOGOFF'] }
      },
    ]
  },
  {
    path: '/login',
    component: DashboardLayout,
    redirect: '/login/acesso',
    children: [
      {
        path: 'acesso',
        name: 'Acesso',
        component: LoginAcesso,
        meta: { permissions: ['LOGOFF'] }
      },
      {
        path: 'recuperarSenha',
        name: 'RecuperarSenha',
        component: RecuperarSenha,
        meta: { permissions: ['LOGOFF'] }
      },
      {
        path: 'resetSenha/:token',
        name: 'ResetSenha',
        component: ResetarSenha,
        meta: { permissions: ['LOGOFF'] }
      },
    ]
  },
  {
    name: 'Suporte',
    path: '/Suporte',
    component: Suporte,
    meta: { permissions: ['LOGOFF'] }
  },  
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/agenda',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/denied',
        name: 'Denied',
        component: Denied,
        meta: { permissions: ['LOGOFF'] }
      },      
      {
        path: 'minhaConta',
        name: 'MinhaConta',
        component: MinhaConta,
      },
      {
        path: 'editadmregister',
        name: 'EditAdmRegister',
        component: EditAdmRegister,
        meta: { permissions: ['CAD_USUARIO'] }
      },
      {
        path: 'userregister',
        name: 'UserRegister',
        component: UserRegister,
        meta: { permissions: ['CAD_USUARIO'] }
      },
      {
        path: 'agenda',
        name: 'Agenda',
        component: Agenda,
        meta: { permissions: ['AGENDA'] }
      },
      {
        path: 'agenda/fechamento/:idFechamento',
        name: 'AgendaFechamento',
        component: Agenda
      },
      {
        path: 'mensagens',
        name: 'Mensagens',
        component: Mensagens
      },
      {
        path: 'relatorios',
        name: 'Relatorios',
        component: Relatorios
      },
      {
        path: 'relatorio-financeiro',
        name: 'RelatorioFinanceiro',
        component: RelatorioFinanceiro,
        meta: { permissions: ['RELATORIO_FINANCEIRO'] }
      },
      {
        path: 'relatorio-terceiro',
        name: 'RelatorioTerceiro',
        component: RelatorioTerceiro,
        meta: { permissions: ['RELATORIO_FINANCEIRO'] }
      },
      {
        path: 'fechamentos',
        name: 'Fechamentos',
        component: Fechamentos,
        meta: { permissions: ['FECHAMENTOS'] }
      },
      {
        path: 'escala',
        name: 'Escala',
        component: Escala,
        meta: { permissions: ['ESCALA'] }
      },
      {
        path: 'credenciamentos',
        name: 'Credenciamentos',
        component: Credenciamentos,
      },
      {
        path: 'credenciamentoi',
        name: 'Credenciamentoi',
        component: ListCredenciamentoPF,
      },
      {
        path: 'pos-criar-conta',
        name: 'PosCriarConta',
        component: PosCriarConta,
      },
      {
        path: 'pos-enviar-credenciamento',
        name: 'PosEnviarCredenciamento',
        component: PosEnviarCredenciamento,
      },
      {
        path: 'dash-credenciamento',
        name: 'DashCredenciamento',
        component: DashCredenciamento,
      },
      {
        path: 'rel-credenciamento',
        name: 'RelatoriosCredenciamento',
        component: RelatoriosCredenciamento,
      },
      {
        path: 'icons',
        name: 'Icons',
        component: Icons
      },
      {
        path: 'maps',
        name: 'Maps',
        component: Maps
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications
      },
      {
        path: 'upgrade',
        name: 'Upgrade to PRO',
        component: Upgrade
      },
      {
        path: 'cad/local',
        name: 'ListLocal',
        component: ListLocal,
        meta: { permissions: ['CAD_LOCAL'] }
      },
      {
        path: 'cad/usuario',
        name: 'ListUsuario',
        component: ListUsuario,
        meta: { permissions: ['CAD_USUARIO'] }
      },
      {
        path: 'cad/profissional',
        name: 'ListProfissional',
        component: ListProfissional,
        meta: { permissions: ['CAD_PROFISSIONAL'] }
      },
      {
        path: 'cad/tipo',
        name: 'ListTipo',
        component: ListTipo,
        meta: { permissions: ['CAD_TIPO'] }
      },
      {
        path: 'cad/grupo-usuario',
        name: 'ListGrupoUsuario',
        component: ListGrupoUsuario,
        meta: { permissions: ['CAD_GRUPO_USUARIO'] }
      },
      {
        path: 'cad/empresa-pj',
        name: 'ListEmpresaPJ',
        component: ListEmpresaPJ,
        meta: { permissions: ['CAD_EMPRESA_PJ'] }
      },
      {
        path: 'cad/empresa',
        name: 'ListEmpresa',
        component: ListEmpresa,
        meta: { permissions: ['CAD_EMPRESA'] }
      },
      {
        path: 'cad/situacao',
        name: 'ListSituacao',
        component: ListSituacao,
        meta: { permissions: ['CAD_SITUACAO'] }
      },
      {
        path: 'cad/classificacao',
        name: 'ListClassificacao',
        component: ListClassificacao,
        meta: { permissions: ['CAD_CLASSIFICACAO'] }
      },
      {
        path: 'manutencao-agenda',
        name: 'ManutencaoAgenda',
        component: ManutencaoAgenda,
        meta: { permissions: ['MANUTENCAO_AGENDA'] }
      },
      {
        path: 'relatorio',
        name: 'Relatorio',
        component: Relatorio,
        meta: { permissions: ['RELATORIO_GERAL'] }
      },
      {
        path: 'testeAgenda',
        name: 'TesteAgenda',
        component: TesteAgenda
      },
      {        
        path: 'teste',
        name: 'Teste',
        component: Teste,
        meta: { permissions: ['LOGOFF'] }
      },
      {
        path: 'testeWebSocket',
        name: 'TesteWebSocket',
        component: TesteWebSocket,
        meta: { permissions: ['LOGOFF'] }
      },
      {
        path: 'list-credenciamento',
        name: 'ListCredenciamento',
        component: ListCredenciamento,
        meta: { permissions: ['LISTA_CREDENCIAMENTO'] }
      },
      {
        path: 'list-credenciamento-pj',
        name: 'ListCredenciamentoPJ',
        component: ListCredenciamentoPJ,
        meta: { permissions: ['LISTA_CREDENCIAMENTO_PJ'] }
      },
      {
        path: 'list-candidato',
        name: 'ListCandidato',
        component: ListCandidato,
        meta: { permissions: ['LISTA_CANDIDATO'] }
      },
      {
        path: 'list-candidatura',
        name: 'ListCandidatura',
        component: ListCandidatura,
        meta: { permissions: ['LISTA_CANDIDATURA'] }
      },
      {
        path: 'list-aprovacao',
        name: 'ListAprovacao',
        component: ListAprovacao,
        meta: { permissions: ['LISTA_APROVACAO'] }
      },
      {
        path: 'aprovacao/:idLiberacao',
        name: 'Aprovacao',
        component: Aprovacao,
        meta: { permissions: ['LISTA_APROVACAO'] }
      },
    ]
  },  
  {
    path: '/prof',
    component: DashboardLayout,
    redirect: '/prof/pagamento',
    children: [
      {
        path: 'pagamento',
        name: 'Pagamento',
        component: ListPagamento
      }
    ]    
  },
  {
    path: '/form',
    component: Login,
    redirect: '/Form/:idForm',
    children: [
      {
        path: ':idForm',
        component: Form
      },
    ]
  },
  {
    path: '/forms',
    component: DashboardLayout,
    redirect: 'forms/cadastro/:idForm',
    children: [
      {
        path: 'cadastro/:idForm',
        name: 'FormCadastro',
        component: FormCadastro,
        meta: { permissions: ['LOGOFF'] }
      },
      {
        path: 'confirmacao',
        name: 'FormCadastroSucesso',
        component: FormCadastroSucesso
      }
    ]
  },
  {
    path: '/:empresa',
    component: DashboardLayout,
    redirect: '',
    children: [
      {
        path: 'credenciamento',
        name: 'Credenciamento',
        component: Credenciamento,
        meta: { permissions: ['LOGOFF'] }
      },
      {
        path: 'criarConta',
        name: 'CriarConta',
        component: CriarConta,
        meta: { permissions: ['LOGOFF'] }
      },
      {
        path: 'muralVaga',
        name: 'MuralVaga',
        component: MuralVaga,
        meta: { permissions: ['LOGOFF'] }
      },
      {
        path: 'vaga/:idAgenda',
        name: 'Vaga',
        component: Vaga,
        meta: { permissions: ['LOGOFF'] }
      },
      /*{
        path: 'especializacao',
        name: 'Especializacao',
        component: Especializacao,
        meta: { permissions: ['LOGOFF'] }
      },*/
    ]
  },
  {
    path: '*', component: NotFound,
  }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes
