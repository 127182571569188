import axios from 'axios';
import authHeader from './auth-header';
import { BASE_CONSTANTS } from "./config";

export default function HTTP() {
  return axios.create({
    baseURL: BASE_CONSTANTS.urlBase,
    headers: authHeader()
  })
}
