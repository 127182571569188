import axios from 'axios';
import jwt_decode from "jwt-decode";
import { BASE_CONSTANTS } from './config';

class AuthService {

  login(user) {

    /*
    var formData = new FormData()
    formData.append('username', user.username)
    formData.append('password', user.password)
    formData.append('grant_type', 'password')

    return axios.post(BASE_CONSTANTS.urlBase + 'oauth/token', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic cmNiYXNza2V5OnNlY3JldA=='
      }
    })
     */

    var dados = {
      username: user.username,
      password: user.password
    }

    return axios.post('login', dados, {
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      
      if (response && response.data.accessToken) {

        let token = JSON.stringify(response.data.accessToken)
        let decoded = jwt_decode(token);

        localStorage.setItem('authorization', JSON.stringify(response.data.accessToken))
        //localStorage.setItem('refresh_token', JSON.stringify(response.data.refresh_token))
        
        let http = axios.create({
          baseURL: BASE_CONSTANTS.urlBase,
          headers: {
            'Authorization': 'bearer ' + String(token).replaceAll('"', ''),
            'Content-Type': 'application/json',
            'Accept': '*/*'
          }
        })

        return http.get('usuario/dados?login=' + decoded.sub)
          .then(resposta => {            
            let retorno = resposta.data;
            let user = retorno.obj;
            localStorage.setItem('dadosUsuario', JSON.stringify(user))                        
            return user;            
          })

      } else {
        throw new Error('Usuário e/ou senha incorreto(s)');
      }

    }).catch(error => {
      return Promise.reject(error);
    });

  }
  logout() {
    localStorage.removeItem('dadosUsuario');
    localStorage.setItem('authorization', null)
    localStorage.setItem('refresh_token', null)
  }
  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }


}
export default new AuthService();