export default function authHeader() {
  let token = JSON.parse(localStorage.getItem('authorization'));
  if (token) {
    return {
      Authorization: 'Bearer ' + token,
      "Content-Type": "application/json",
      Accept: "*/*",
    };
  } else {
    return {      
      "Content-Type": "application/json"
    };
  }

}