<template>

</template>

<script>

import axios from 'axios'
import { BASE_CONSTANTS } from '../service/config'
import AgendaService from '../service/AgendaService'

export default {

    components: {
                
    },
    data() {
        return {
            
        }
    },

    mounted() {
        
    },
    methods: {
        http(){
            return axios.create({
            baseURL: BASE_CONSTANTS.urlBase,
            headers: {
                'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"',''),
                'Content-Type': 'application/json',
                'Accept': '*/*'
            }})
        },
        possuiPermissao(menu, permissao){        
            let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));            
                let perm = dadosUsuario.grupo.permissoes.filter(function(elem){      
                if(elem.menu == menu && elem.permissao == permissao) return elem;
                });
            return perm !== null && perm.length>0 ? perm[0].nivel : 'E';  
        },
        downloadExcel(data) {
            var temp = 'data:application/vnd.ms-excel;base64,'+encodeURIComponent(data);  
            var download = document.createElement('a');  
            download.href = temp;  
            download.download = "arquivo.xlsx";
            document.body.appendChild(download);  
            download.click();
            document.body.removeChild(download);
        },
        downloadTxt(data) {
            var temp = 'data:text/plain;base64,'+encodeURIComponent(data);  
            var download = document.createElement('a');  
            download.href = temp;  
            download.download = "arquivo.txt";
            document.body.appendChild(download);  
            download.click();
            document.body.removeChild(download);
        },
        downloadJson(data) {
            var temp = 'data:text/plain;base64,'+encodeURIComponent(data);  
            var download = document.createElement('a');  
            download.href = temp;  
            download.download = "arquivo.json";
            document.body.appendChild(download);  
            download.click();
            document.body.removeChild(download);
        },
        downloadPdf(data) {            
            var temp = 'data:application/pdf;base64,'+encodeURIComponent(data);  
            var download = document.createElement('a');  
            download.href = temp;  
            download.download = "arquivo.pdf";
            document.body.appendChild(download);  
            download.click();
            document.body.removeChild(download);
        },
        download(data, fileName) {                        
            var parts = fileName.split('.');            
            var ext = parts[parts.length - 1];
            var type = ext;
            if (ext === 'xlsx' || ext === 'xls') type = 'vnd.ms-excel';
            if (ext === 'txt') type = 'text/plan';
            var temp = 'data:application/'+type+';base64,'+encodeURIComponent(data);  
            var download = document.createElement('a');  
            download.href = temp;  
            download.download = fileName;
            document.body.appendChild(download);  
            download.click();
            document.body.removeChild(download);
        },
        downloadData(data, fileName) {                                    
            var download = document.createElement('a');  
            download.href = data;  
            download.download = fileName;
            document.body.appendChild(download);  
            download.click();
            document.body.removeChild(download);
        },
        async exportarHmtj(ids){
            let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
            let dadosConsulta = '{ "idUsuario": ' + dadosUsuario.idPessoa +
            ',"ids":[' + ids + ']' +        
            '}';      

            return AgendaService.exportarRelatorioFinanceiroHmtj(this.http(), dadosConsulta)
            
        },
        
        async alterarSituacaoFechamento(idFechamento, idSituacaoFechamento) {
            this.show = true            
            let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))
            let dadosPost = '{ "idFechamento": ' + idFechamento +
            ', "idSituacao":' + idSituacaoFechamento + ' '+
            ', "idUsuario": ' +dadosUsuario.idPessoa + '}';
            return AgendaService.alterarSituacaoFechamentoAgenda(this.http(), dadosPost)
        },
    }

}
</script>
<style>
</style>