<template>
    <div class="chat-container d-flex vh-100">
        <!-- User List (Left Sidebar) -->
        <div class="user-list d-flex flex-column p-3 bg-light border-end" style="width: 250px;">
            <h4 class="mb-3 text-uppercase text-decoration-underline text-muted">{{ loggedInUsername }}</h4>
            <!-- Logout Button (Over User List Sidebar) -->
            <div class="pt-3">
                <button @click="logout" class="btn btn-dark w-100">Logout</button>
            </div>
        </div>

        <!-- Chat Panel -->
        <div class="chat-panel flex-grow-1 d-flex flex-column">
            <div class="chat-header p-3 bg-white shadow-sm text-center">
                <h5>{{ selectedRecipient ? selectedRecipient.username : 'Select a user to chat' }}</h5>
            </div>

            <div v-if="qrCode">            
              <qrcode-vue :value="qrCode" :size="300" level="H"></qrcode-vue>
            </div>

            <!-- Chat Body -->
            <div class="chat-body p-3 flex-grow-1 position-relative">
                <!-- Loader component when data is being fetched -->
                
                <div v-if="!isLoading && selectedRecipient">
                    <div v-for="message in chatHistory" :key="message.id" class="message">
                        <!-- Differentiate messages by sender -->
                        <div :class="{'text-end': message.sender.username === loggedInUsername}">
                            <div :class="message.sender.username === loggedInUsername ? 'bg-primary text-white p-2 rounded d-inline-block' : 'bg-secondary text-white p-2 rounded d-inline-block'">
                                {{ message.content }}
                            </div>
                            <small class="d-block text-muted my-1">
                                {{ message.timestamp }}
                                <!-- Message Status (seen, delivered, sent) -->
                                <span v-if="message.sender.username === loggedInUsername" class="ms-2">
                                    <i v-if="message.seen" class="bi bi-check2-all text-primary"></i> <!-- Blue double tick (seen) -->
                                    <i v-else-if="message.delivered" class="bi bi-check2-all text-secondary"></i> <!-- Gray double tick (delivered) -->
                                    <i v-else class="bi bi-check2 text-muted"></i> <!-- Single gray tick (sent) -->
                                </span>
                            </small>
                        </div>
                    </div>
                </div>
                <div v-else-if="!isLoading" class="text-muted text-center mt-5">No user selected.</div>
            </div>

            <div class="chat-footer p-3 bg-light">
                <input :disabled="!selectedRecipient || !websocketConnected" v-model="newMessage" type="text" class="form-control" placeholder="Type a message..." @keyup.enter="sendMessage" />
            </div>
        </div>
    </div>
</template>

<script>
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import QrcodeVue from 'qrcode.vue'

export default {
    components: {QrcodeVue},
    data() {
        return {
            qrCode: null,
            websocketConnected: false,
            stompClient: null,
            loggedInUser: null,
            loggedInUsername: null,
            users: [],
            selectedRecipient: null,
            chatHistory: [],
            newMessage: '',
            isLoading: false
        };
    },
    methods: {
                
        // Establish WebSocket connection and set up subscriptions
        connectToWebSocket() {
            const socket = new SockJS(`http://localhost:5477/ws?username=${this.loggedInUsername}`);
            this.stompClient = Stomp.over(socket);

            // Connect to WebSocket
            this.stompClient.connect({'X-Username': this.loggedInUsername}, (frame) => {
                this.websocketConnected = true;
                console.log('Connected to WebSocket:', frame);

                // Subscribe to receive messages for sender
                this.stompClient.subscribe('/topic/qrCode', (message) => {
                    const listening = JSON.parse(message.body);                    
                    this.handleSeenMessage(listening);
                });
                
            });
        },
        // Handle seen message notifications and update the chat history
        handleSeenMessage(seenMessage) {          
          console.log(JSON.stringify(seenMessage.texto))
          this.qrCode = JSON.stringify(seenMessage.texto)
            /*if (this.isCurrentChatHistory(seenMessage.senderUsername, seenMessage.recipientUsername)) {
                this.chatHistory = this.chatHistory.map(chat => {
                    if (chat.sender.username === seenMessage.recipientUsername) {
                        return {...chat, seen: true, delivered: true};
                    }
                    return chat;
                });
            }*/
        },
        
        // Send a message to the selected recipient
        sendMessage() {
            if (this.isLoading || !(this.newMessage && this.selectedRecipient)) return;
            const message = {
                senderUsername: this.loggedInUsername,
                recipientUsername: this.selectedRecipient.username,
                content: this.newMessage
            };
            this.stompClient.send('/app/chat.sendMessage', {}, JSON.stringify(message));
            this.newMessage = ''; // Clear the input field
        },
        // Handle user logout and disconnect WebSocket
        logout() {
            if (!this.websocketConnected) return;
            logout(this.loggedInUser.id).then(() => {
                localStorage.removeItem("user");
                setTimeout(() => {
                    this.$router.push('/login');
                }, 200);
            });
        }
    },
    created() {      
        this.loggedInUser = JSON.parse(localStorage.getItem('dadosUsuario'));      
        if (this.loggedInUser) {
            this.loggedInUsername = this.loggedInUser.nome;
            this.connectToWebSocket();
        } else {
            this.$router.push('/login');
        }
    },
    mounted() {
      
        //this.loadUsers();
    },
    unmounted() {
        if (this.stompClient && this.websocketConnected) {
            this.stompClient.disconnect();
            this.websocketConnected = false;
        }
    }
};
</script>


<style scoped>
.chat-container {
    height: 100vh;
}
.user-list {
    width: 300px;
    border-right: 1px solid #dee2e6;
}
.chat-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.chat-header {
    border-bottom: 1px solid #dee2e6;
}
.chat-body {
    overflow-y: auto;
    max-height: calc(100vh - 160px);
}
.chat-footer {
    border-top: 1px solid #dee2e6;
}
.message {
    margin-bottom: 15px;
}
</style>
