<template>
  <div style="min-height: 500px">

    <b-modal ref="modal-conf-canc-fecha" id="modal-conf-canc-fecha" size="lg" title="Confirmação" hide-footer>
      <div class="container-fluid">
        <b-row class="my-1">
          <div class="col-md-12">
            {{ msgConfirma }}
          </div>
        </b-row>
        <b-row class="my-1">
          <div class="col-md-12">
            <button size="sm" style="margin-left:4px;" class="btn btn-danger btn-fill float-right"
              @click="$bvModal.hide('modal-conf-canc-fecha')">
              Não
            </button>
            <button size="sm" class="btn btn-success btn-fill float-right" @click="cancelarConferencia">
              Sim
            </button>
          </div>
        </b-row>
      </div>
    </b-modal>

    <b-modal header-bg-variant="primary" header-text-variant="light" 
      footer-bg-variant="light"
      ref="previsto-realizado" id="modal-previsto-realizado"
      title="Previsto x Realizado" size="xl">
    
      <previsto-realizado-fechamento :itens="itensTotal"></previsto-realizado-fechamento>
    
      <template #modal-footer="{ cancel }">
        <div class="w-100">          
            <b-button size="md" variant="danger" class="btn-fill float-right" @click="cancel()">
              Fechar
            </b-button>          
          </div>
      </template>
    
    </b-modal>

    <confirma-relatorio-financeiro id="consolidado" ref="confRelFinConsolidado" @executou="(r) => { if(r.status === 'success') exportarExcelConsolidado(r.escolha) }"/>
    <confirma-relatorio-financeiro id="detalhado" ref="confRelFinDetalhado" @executou="(r) => { if(r.status === 'success') exportarExcelDetalhado(r.escolha) }"/>

    <card>

      <template slot="header">
        <h4 class="card-title">Relatório Financeiro</h4>
      </template>

      <div class="container-fluid c-form rel-financeiro">

        <div class="row">

          <div class="col-md-4">
            <filtro-local :itemLocal="itemLocal" @update="itemLocal = $event;" ></filtro-local>
          </div>

          <div class="col-md-4 filtro">
            <b-input-group>

              <b-button variant="primary" size="sm" class="mb-2 mr-1" @click="goMesAnterior">
                <b-icon icon="arrow-left-circle" ></b-icon>
              </b-button>

              <b-form-datepicker id="dtInicio" v-model="dtInicio" size="sm" class="mb-2" placeholder="Início do período"
                today-button reset-button close-button today-button-variant="primary" reset-button-variant="danger"
                close-button-variant="success" label-no-date-selected="Nenhuma data selecionada"
                label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
              </b-form-datepicker>

              <b-input-group-text class="mb-2">até</b-input-group-text>

              <b-form-datepicker id="dtFim" v-model="dtFim" size="sm" class="mb-2" placeholder="Início do período"
                today-button reset-button close-button today-button-variant="primary" reset-button-variant="danger"
                close-button-variant="success" label-no-date-selected="Nenhuma data selecionada"
                label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
              </b-form-datepicker>

              <b-button variant="primary" size="sm" class="mb-2 ml-1" @click="goMesSeguinte">
                <b-icon icon="arrow-right-circle" ></b-icon>
              </b-button>

            </b-input-group>
          </div>

          <div class="col-md-1 mt-1 pl-2">
            <b-form-checkbox v-model="individual" value="S" unchecked-value="N" @change="individualChange" class="custom-checkbox" switch>
            Individual
            </b-form-checkbox>
          </div>
          <div class="col-md-3">
            <button type="button" style="margin-left:4px;" class="btn btn-primary btn-fill btn-sm float-left"
              @click="gerarRelatorio">
              <b-icon icon="search"></b-icon>
              Exibir
            </button>
            <button v-if="false" type="Salvar" v-show="dadosRelatorio.itens !== null" style="margin-left:4px;"
              class="btn btn-info btn-fill btn-sm float-left" @click="exportarExcel">
              Exportar pedido
            </button>
            
            <b-dropdown text="Relatórios" variant="primary" class="ml-2" size="sm">              
              <b-dropdown-item @click="confirmaRelatorioFinanceiro">Consolidado</b-dropdown-item>
              <b-dropdown-item @click="confirmaRelatorioDetalhado" href="#">Detalhado</b-dropdown-item>                            
            </b-dropdown>
          </div>
        </div>

        <div class="row registros">

          <div class="col-12">
            <b-table id="tbl-relatorio" :fixed="true" :bordered="false" stacked="md" sticky-header responsive="true"
            tbody-tr-class="grid-tr" class="grid-js" head-variant="light"
              hover :items="dadosRelatorio.itens" :fields="fields">

              <template #head(selecione)>
                <b-form-checkbox v-model="todosSelecionados" @input="selecionarTodos"></b-form-checkbox>
              </template>

              <template v-slot:cell(selecione)="row">
                <b-form-checkbox v-model="row.item.selecione"></b-form-checkbox>
              </template>

              <template v-slot:cell(valorVenda)="data">
                {{ data.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }) }}
              </template>

              <template v-slot:cell(valorCompra)="data">
                {{ data.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }) }}
              </template>

              <template v-slot:cell(valorCompl)="data">
                {{ data.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 })}}
              </template>

              <template v-slot:cell(valorAdiantado)="data">
                {{ data.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 })}}
              </template>

            </b-table>

          </div>
        </div>

        <div v-if="dadosRelatorio.quantidade !== undefined" class="row">
          <div class="col-md-3">
            <span class="resumo"> Quantidade: {{ dadosRelatorio.quantidade }} </span>
            <span class="ml-2 resumo">Complemento: {{ dadosRelatorio.totalComplemento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 })}}</span>
          </div>
          
          <div class="col-md-9">
            <b-button v-if="dadosRelatorio.quantidade > 0" size="sm" variant="warning" class="float-left"
              @click="buscarPrevistoRealizado">
              Previsto x Realizado
            </b-button>

            <b-button type="Salvar" v-if="dadosRelatorio.quantidade > 0 && !dadosRelatorio.comFechamento"
              style="margin:4px;" size="sm" variant="primary" class="btn btn-primary btn-fill float-right" @click="confirmarConferencia">
              Aprovar lançamentos
            </b-button>
            <b-button type="Salvar" v-if="false && dadosRelatorio.comFechamento" style="margin:4px;" size="sm"
              class="btn btn-danger btn-fill float-right" @click="confirmaCancelaConferencia">
              Cancelar fechamento
            </b-button>
          </div>
        </div>

      </div>
    </card>
  </div>
</template>

<script>
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../service/config'
import AgendaService from '../service/AgendaService'
import { relatorio } from '../model/relatorio'
import appUtils from '@/composables/utils'
import PrevistoRealizadoFechamento from './PrevistoRealizadoFechamento.vue'
import FiltroLocal from './comuns/FiltroLocal'
import funcao from "src/components/Funcao";
import ConfirmaRelatorioFinanceiro from './comuns/ConfirmaRelatorioFinanceiro.vue'

export default {
  components: {
    LTable,
    Card,
    PrevistoRealizadoFechamento,
    FiltroLocal,
    ConfirmaRelatorioFinanceiro
  },
  data() {
    return {
      survey: null,
      show: false,
      showFrom: "",
      filaShow: [],
      msg: null,
      itensLocal: [],
      itemLocal: null,
      dtInicio: null,
      dtFim: null,
      exibirValorVenda: false,
      exibirValorCompra: false,
      exibirGrupo: false,
      //fields: [],
      dadosRelatorio: [],
      showConfirma: false,
      msgConfirma: null,
      perPage: 10,
      currentPage: 1,
      itensTotal: null,
      todosSelecionados: 'N',
      individual: 'N'
    }
  },
  mounted() {

    const { ativarShow, desativarShow } = appUtils(this)

    const token = String(localStorage.getItem('authorization')).replaceAll('"', '')

    if (token === null || token === undefined || token === '' || token === 'null') {
      this.$route.router.go('/login');
    } else {
      /*axios.get(BASE_CONSTANTS.urlBase + 'oauth/check_token/?token=' + token).then(resposta => {
        this.carregaDados()
      }).catch(error => {
        if (error === false) {
          this.$router.push('/login');
        }
      })*/
    }

    this.dadosRelatorio = []

    ativarShow("buscarLocais");
    let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

    this.meses = [{ value: 1, text: 'Janeiro' }, { value: 2, text: 'Fevereiro' }, { value: 3, text: 'Março' }, { value: 4, text: 'Abril' }, { value: 5, text: 'Maio' },
    { value: 6, text: 'Junho' }, { value: 7, text: 'Julho' }, { value: 8, text: 'Agosto' }, { value: 9, text: 'Setembro' }, { value: 10, text: 'Outubro' },
    { value: 11, text: 'Novembro' }, { value: 12, text: 'Dezembro' }]

    const dataAtual = new Date()
    const anoAtual = dataAtual.getFullYear()
    this.anos = [anoAtual - 1, anoAtual]

    this.mes = dataAtual.getMonth() + 1
    this.ano = anoAtual

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd")
    this.dtFim = dateToStr(lastDay, "yyyy-MM-dd")

    this.exibirValorVenda = this.hasPermission('RELATORIO_FINANCEIRO', 'PRECO_VENDA', 'E')
    this.exibirValorCompra = this.hasPermission('RELATORIO_FINANCEIRO', 'PRECO_COMPRA', 'E')

    let dadosConsulta = {
        "idEmpresa": dadosUsuario.grupo.empresa.id,
        "identificadores": ["EXIBIR_GRUPO_RELATORIO_FINANCEIRO"]
      }

    AgendaService.buscarParametroCadastro(dadosConsulta)
      .then(resposta => {
        let params = resposta.data.obj        
        this.exibirGrupo = params.filter((f) => f.parametro.identificador === 'EXIBIR_GRUPO_RELATORIO_FINANCEIRO' && f.valor === 'S').length > 0;
        this.carregarCampos();
        desativarShow("buscarLocais");
      }).catch(error => {
        desativarShow("buscarLocais");
      })

  },
  methods: {
    goMesAnterior() {
      const dataAtual = new Date(this.dtInicio+"T00:00:00")
      dataAtual.setMonth(dataAtual.getMonth()-1);
      var firstDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1)
      var lastDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0)
      this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd")
      this.dtFim = dateToStr(lastDay, "yyyy-MM-dd")
    },
    goMesSeguinte() {      
      const dataAtual = new Date(this.dtInicio+"T00:00:00")
      dataAtual.setMonth(dataAtual.getMonth()+1);            
      var firstDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1)
      var lastDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0)
      this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd")
      this.dtFim = dateToStr(lastDay, "yyyy-MM-dd")
    },

    carregarCampos() {



    },
    exportarExcel(dadosRelatorio) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarExcel");

      relatorio.itens = this.dadosRelatorio.itens
      relatorio.dtInicio = this.dtInicio
      relatorio.dtFim = this.dtFim
      relatorio.locaisStr = this.dadosRelatorio.locais
      relatorio.nomeRelatorio = this.dadosRelatorio.nomeRelatorio
      relatorio.itens = null
      relatorio.idLocal = this.itemLocal !== null ? this.itemLocal.id : 0

      AgendaService.exportarRelatorioFinanceiroExcel(relatorio).then(resposta => {
        desativarShow("exportarExcel");
        let retorno = resposta.data;
        if (retorno.status === 'SUCCESS') {
          var base64 = retorno.obj
          this.downloadExcel(base64)
        } else {
          toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
        }
      }).catch(error => {
        desativarShow("exportarExcel");
      })

    },
    individualChange(checked) {
      this.dadosRelatorio = []
      this.gerarRelatorio();
    },
    gerarRelatorio() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      this.dadosRelatorio.data = []

      ativarShow("gerarRelatorio");

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idTabela": dadosUsuario.grupo.idTabela,
        "idEmpresa": dadosUsuario.grupo.empresa.id,
        "fechamento": "S",
        "idLocal": this.itemLocal !== null ? this.itemLocal.id : 0,
        "dtInicio": this.dtInicio,
        "dtFim": this.dtFim,
        "individual": this.individual
      }

      AgendaService.geraRelatorioFinanceiro(dadosEnvio)
        .then(resposta => {
          desativarShow("gerarRelatorio");
          let retorno = resposta.data;
          if (retorno.status === 'SUCCESS') {
            this.dadosRelatorio = retorno.obj
            this.todosSelecionados = true
          } else {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          }

        }).catch(error => {
          desativarShow("gerarRelatorio");
        })

    },
    confirmarConferencia() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("confirmarConferencia");

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let idsAgenda = [];

      if (this.individual === 'S') {
        idsAgenda = this.dadosRelatorio.itens.filter(i => i.selecione).map(i => i.idAgenda)
      } else {
        this.dadosRelatorio.idsAgenda.forEach((item) => {
          idsAgenda.push(item);
        })
      }

      let dadosEnvio = {
          "idUsuario": dadosUsuario.idPessoa,
          "idTabela": dadosUsuario.grupo.idTabela,
          "dtIni": this.dtInicio,
          "dtFim": this.dtFim,        
          "idLocal": (this.itemLocal !== null ? this.itemLocal.id : 0),
          "idsAgenda": idsAgenda,          
        }

      AgendaService.gravarFechamentoAgenda(dadosEnvio)
        .then(resposta => {
          desativarShow("confirmarConferencia");
          let retorno = resposta.data;
          if (retorno.status === 'SUCCESS') {
            this.dadosRelatorio.comFechamento = true
            toast(retorno.mensagens[0].descricao, 'Sucesso', 'success')
          } else {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          }

        }).catch(error => {
          desativarShow("confirmarConferencia");
        })
    },
    confirmaCancelaConferencia() {
      this.msgConfirma = "Confirma cancelamento do fechamento?"
      this.showConfirma = true
      this.$refs['modal-conf-canc-fecha'].show()
    },
    cancelarConferencia(dadosRelatorio) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("confirmarConferencia");
      this.$refs['modal-conf-canc-fecha'].hide()

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosEnvio = '{ "dtIni":"' + this.dtInicio + '","dtFim":"' + this.dtFim + '", "idUsuario": ' + dadosUsuario.idPessoa + ', "idLocal": ' + (this.itemLocal !== null ? this.itemLocal.id : 0) + '}'

      AgendaService.cancelarFechamentoAgenda(dadosEnvio)
        .then(resposta => {
          desativarShow("confirmarConferencia");
          let retorno = resposta.data;
          if (retorno.status === 'SUCCESS') {
            this.dadosRelatorio.comFechamento = false
            toast(retorno.mensagens[0].descricao, 'Sucesso', 'success')
          } else {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          }
          desativarShow("confirmarConferencia");
        }).catch(error => {
          desativarShow("confirmarConferencia");
        })
    },
    buscarPrevistoRealizado() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarPrevistoRelizado");

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let idsAgenda = [];

      this.dadosRelatorio.idsAgenda.forEach((item) => {
        idsAgenda.push(item);
      });

      let dados = {
        dtInicio: "'" + this.dtInicio + "'",
        dtFim: "'" + this.dtFim + "'",
        idUsuario: dadosUsuario.idPessoa,
        idLocal: this.itemLocal !== null ? this.itemLocal.id : 0,
        ids: idsAgenda
      }

      AgendaService.buscarTotaisFechamentoMes(dados)
        .then(resposta => {
          desativarShow("buscarPrevistoRelizado");
          let retorno = resposta.data;
          if (retorno.status === 'SUCCESS') {
            this.itensTotal = retorno.obj
            this.$refs['previsto-realizado'].show()
          } else {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          }
        }).catch(error => {
          desativarShow("buscarPrevistoRelizado");
        })

    },
    exportarExcelConsolidado(op) {
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarExcelConsolidado")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idTabela": dadosUsuario.grupo.idTabela,
        "idEmpresa": dadosUsuario.perfilCoordenador === 'S' ? dadosUsuario.grupo.empresa.id : null,
        "fechamento": "S",
        "idLocal": this.itemLocal !== null ? this.itemLocal.id : 0,
        "dtInicio": this.dtInicio,
        "dtFim": this.dtFim,        
        "comPrecoVenda": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorVenda' }).length == 1 ? 'S' : 'N',
        "comPrecoCompra": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorCompra' }).length == 1 ? 'S' : 'N',        
        "comTipoServico": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'tipoServico' }).length == 1 ? 'S' : 'N',
        "comTipoApuracao": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'tipoApuracao' }).length == 1 ? 'S' : 'N',
        "comPrecoUnitario": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorUnitario' }).length == 1 ? 'S' : 'N',
        "comRazaoPJ": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'razaoPJ' }).length == 1 ? 'S' : 'N'
      }

      AgendaService.exportarRelatorioFinanceiroConsolidado(dadosEnvio)
        .then((resposta) => {
          desativarShow("exportarExcelConsolidado")
          let retorno = resposta.data;          
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadExcel(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarExcelConsolidado")
        });
    },
    exportarExcelDetalhado(op) {
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarExcelDetalhado")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idTabela": dadosUsuario.grupo.idTabela,
        "idEmpresa": dadosUsuario.perfilCoordenador === 'S' ? dadosUsuario.grupo.empresa.id : null,
        "fechamento": "S",
        "idLocal": this.itemLocal !== null ? this.itemLocal.id : 0,
        "dtInicio": this.dtInicio,
        "dtFim": this.dtFim,        
        "comPrecoVenda": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorVenda' }).length == 1 ? 'S' : 'N',
        "comPrecoCompra": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorCompra' }).length == 1 ? 'S' : 'N',        
        "comTipoServico": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'tipoServico' }).length == 1 ? 'S' : 'N',
        "comPrecoUnitario": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorUnitario' }).length == 1 ? 'S' : 'N'
      }

      AgendaService.exportarRelatorioFinanceiroDetalhado(dadosEnvio)
        .then((resposta) => {
          desativarShow("exportarExcelDetalhado")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadExcel(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarExcelDetalhado")
        });
    },
    confirmaRelatorioFinanceiro() {      
      let colunas = []
      if (this.exibirValorVenda) colunas.push({ text: 'Valor de venda', value: 'valorVenda' })
      if (this.exibirValorCompra) colunas.push({ text: 'Valor de compra', value: 'valorCompra' })
      colunas.push({ text: 'Tipo de serviço', value: 'tipoServico' })
      colunas.push({ text: 'Tipo de apuração', value: 'tipoApuracao' })
      if (this.exibirValorVenda || this.exibirValorCompra) colunas.push({ text: 'Valor unitário', value: 'valorUnitario' })      

      colunas.push({ text: 'PJ', value: 'razaoPJ' })
      
      this.$refs.confRelFinConsolidado.show(colunas)
    },
    confirmaRelatorioDetalhado() {   

      let colunas = []
      if (this.exibirValorVenda) colunas.push({ text: 'Valor de venda', value: 'valorVenda' })
      if (this.exibirValorCompra) colunas.push({ text: 'Valor de compra', value: 'valorCompra' })      

      if (colunas.length > 0) {
        this.$refs.confRelFinDetalhado.show(colunas)
      } else {
        this.exportarExcelDetalhado()
      }
    },
    selecionarTodos(checked) {
      this.dadosRelatorio.itens.forEach((item) => {
        item.selecione = checked;
      });
    },
    downloadExcel(data) {
      var temp = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(data);
      var download = document.createElement('a');
      download.href = temp;
      download.download = "arquivo.xlsx";
      document.body.appendChild(download);
      download.click();
      document.body.removeChild(download);
    },
    hasPermission(menu, permissao, nivel) {
      return this.$store.getters['auth/hasPermissionWith'](menu, permissao, nivel)
    },
  },
  computed: {
    fields() {

      let campos = []

      if (this.individual == 'S') {
        campos.push({ label: '', key: 'selecione', sortable: true, thStyle: { width: "3%" } });
      }

      campos.push({ label: 'Nome', key: 'nomeProfissional', sortable: true, thStyle: { width: "16%" } });
      campos.push({ label: 'CRM', key: 'crm', sortable: true, thStyle: { width: "6%" } });
      campos.push({ label: 'Procedimento', key: 'tipo', sortable: true, thStyle: { width: "19%" } });
      campos.push({ label: 'Qtde plantões', key: 'qtdPlantao', sortable: true, thStyle: { width: "6%" } });
      campos.push({ label: 'Qtde de horas', key: 'duracao', sortable: true, thStyle: { width: "6%" } });

      if (this.exibirValorVenda) {
        campos.push({ label: 'Valor de venda', key: 'valorVenda', sortable: true, thStyle: { width: "9%" } });
      }

      if (this.exibirValorCompra) {
        campos.push({ label: 'Valor de compra', key: 'valorCompra', sortable: true, thStyle: { width: "9%" } });
      }

      if (this.exibirGrupo) {
        campos.push({ label: 'Grupo', key: 'grupo', sortable: true, thStyle: { width: "7%" } });
      }

      campos.push({ label: 'Projeto', key: 'codigoLocal', sortable: true, thStyle: { width: "7%" } });
      campos.push({ label: 'Competência', key: 'competencia', sortable: true, thStyle: { width: "8%" } });
      campos.push({ label: 'Complemento', key: 'valorCompl', sortable: true, thStyle: { width: "9%" } });
      campos.push({ label: 'Adiant.', key: 'valorAdiantado', sortable: true, thStyle: { width: "6%" } });
      campos.push({ label: 'Tipo apuração', key: 'tipoApuracao', sortable: true, thStyle: { width: "9%" } });

      return campos;

    },
    rows() {
      return this.dadosRelatorio.itens === undefined ? 0 : this.dadosRelatorio.itens.length
    }
  }
}

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format.replace('yyyy', date.getFullYear())
      .replace('MM', (date.getMonth() + 1).toString().padStart(2, '0'))
      .replace('dd', date.getDate().toString().padStart(2, '0'))
      .replace('HH', date.getHours().toString().padStart(2, '0'))
      .replace('mm', date.getMinutes().toString().padStart(2, '0'))
      .replace('ss', date.getSeconds().toString().padStart(2, '0'));
    return strDate;
  }
}

</script>
<style lang="scss">

.c-form {
  padding-bottom: 60px;
}

.rel-financeiro{
  
  .filtro, .registros {
    font-size: 0.7rem;
  }  

  .resumo {
    font-size: 0.8rem;
  }

}

</style>
