<template>
  <div>
    <confirma ref="conf" @respondeu="(r) => { if (r === 'S') excluir() }">
      <template v-slot:texto>Confirma exclusão das agendas selecionadas?</template>
    </confirma>

    <edit-agendas @showOverlay="(status) => this.$emit('showOverlay', status)" ref="editAgendas"
      @executou="(r) => { if (r.status === 'success') buscar() }">

    </edit-agendas>

    <confirma-relatorio-financeiro id="consolidado" ref="confRelFinConsolidado"
      @executou="(r) => { if (r.status === 'success') exportarExcelConsolidado(r.escolha) }" />
    <confirma-relatorio-financeiro id="detalhado" ref="confRelFinDetalhado"
      @executou="(r) => { if (r.status === 'success') exportarExcelDetalhado(r.escolha) }" />

    <card>

      <template slot="header">
        <h4 class="card-title">Relatórios</h4>        
      </template>

      <div class="container-fluid c-form manutencao-agenda">

        <div class="row">
          <div class="col-md-12">

            <div class="row">
              <div class="col-md-2 filtro">
                <b-form-select v-model="modelo" :options="optionsFiltroModelo"></b-form-select>
              </div>

              <div class="col-md-12 col-xl-4">
                <b-input-group>
                  <b-button variant="primary" size="sm" class="mb-2  mr-1" @click="goMesAnterior">
                    <b-icon icon="arrow-left-circle"></b-icon>
                  </b-button>
                  <b-form-datepicker id="dtInicio" v-model="dtInicio" size="sm" class="mb-2"
                    placeholder="Início do período" today-button reset-button close-button today-button-variant="primary"
                    reset-button-variant="danger" close-button-variant="success"
                    label-no-date-selected="Nenhuma data selecionada" label-today-button="Hoje"
                    label-reset-button="Limpar" label-close-button="Fechar"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                  </b-form-datepicker>

                  <b-input-group-text class="mb-2">até</b-input-group-text>

                  <b-form-datepicker id="dtFim" v-model="dtFim" size="sm" class="mb-2" placeholder="Início do período"
                    today-button reset-button close-button today-button-variant="primary" reset-button-variant="danger"
                    close-button-variant="success" label-no-date-selected="Nenhuma data selecionada"
                    label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                  </b-form-datepicker>

                  <b-button variant="primary" size="sm" class="mb-2 ml-1" @click="goMesSeguinte">
                    <b-icon icon="arrow-right-circle"></b-icon>
                  </b-button>

                </b-input-group>
              </div>

              <div class="col-md-12 col-xl-4">

                <b-row class="m-0 p-0">
                  <div class="col-md-5">
                    <b-input-group>
                      <b-form-input v-mask="'##:##'" placeholder="Início" v-model="horaInicio" size="sm"></b-form-input>
                      <b-input-group-text class="">até</b-input-group-text>
                      <b-form-input v-mask="'##:##'" placeholder="Fim" v-model="horaFim" size="sm"></b-form-input>
                    </b-input-group>
                  </div>

                  <div class="col-md-2">
                    <b-form-input v-mask="'##:##'" placeholder="Duração" v-model="duracao" size="sm"></b-form-input>
                  </div>

                  <div class="col-md-5">
                    <b-row class="m-0 p-0">
                      <div class="col-md-1 col-1 ml-1" v-for="dia in diasSemana" :key="dia.value">
                        <b-form-group label-align="center" class="text-center"
                          :label-class="dia.value === 'dom' || dia.value === 'sab' ? 'cor-fim-semana' : ''"
                          :label="dia.text" :label-for="'dia-semana-' + dia.value">
                          <b-form-checkbox v-model="dia.selecionado" :id="'dia-semana-' + dia.value"></b-form-checkbox>
                        </b-form-group>
                      </div>
                    </b-row>
                  </div>

                </b-row>

              </div>

              <div class="col-md-4">
                <filtro-local @showOverlay="(status) => this.$emit('showOverlay', status)" :itemLocal="itemLocal"
                  @update="itemLocal = $event; localAlterado()" :multiple="true"></filtro-local>
              </div>

              <div class="col-md-3">
                <v-select multiple v-model="itemProfissional" :options="itensProfissionais" label="nome" ref="select"
                  placeholder="Profissional" @open="buscaProfissionais" :filterBy="filterBy">
                  <span slot="no-options" @click="$refs.select.open = false">
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>

              <div class="col-md-3">
                <v-select multiple v-model="itemTipo" :options="itensTipo" @open="buscaTipos" label="descricao"
                  ref="select" placeholder="Tipo de serviço">
                  <span slot="no-options" @click="$refs.select.open = false">
                    Nenhum tipo de procedimento
                  </span>
                </v-select>
              </div>

              <div class="col-md-2">
                <v-select v-model="itemSituacao" multiple :options="itensSituacao" @open="buscaSituacoes"
                  label="descricao" ref="select" placeholder="Situação">
                  <span slot="no-options" @click="$refs.select.open = false">
                    Nenhuma situação
                  </span>
                </v-select>
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-4 text-center">
            <button type="button" class="btn btn-primary btn-fill btn-sm px-4" @click="buscar">
              <b-icon icon="bar-chart-steps" aria-hidden="true"></b-icon>
              Gerar
            </button>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../service/config'
import AgendaService from '../service/AgendaService'
import appUtils from '@/composables/utils'
import Confirma from './comuns/Confirma'
import EditAgendas from './EditAgendas'
import FiltroLocal from './comuns/FiltroLocal'
import ConfirmaRelatorioFinanceiro from './comuns/ConfirmaRelatorioFinanceiro.vue'
import funcao from "src/components/Funcao";

export default {
  components: {
    LTable,
    Card,
    Confirma,
    EditAgendas,
    FiltroLocal,
    ConfirmaRelatorioFinanceiro,
  },
  data() {
    return {
      survey: null,
      http: null,
      msg: null,
      show: false,
      showFrom: "",
      filaShow: [],
      loading: false,
      search: null,
      itensLocal: [],
      itemLocal: null,
      dtInicio: null,
      dtFim: null,
      horaInicio: null,
      horaFim: null,
      duracao: null,
      itens: [],
      itensTipo: [],
      itensSituacao: [],
      itensProfissionais: [],
      itemProfissional: null,
      itemTipo: null,
      itemSituacao: null,
      fields: [],
      dadosRelatorio: [],
      showConfirma: false,
      msgConfirma: null,
      perPage: 10,
      currentPage: 1,
      todosSelecionados: 'N',
      idsAgendaSelecionada: [],
      selected: [],
      exibirValorVenda: false,
      exibirValorCompra: false,
      isRelEscalaPdf: false,
      isRelEscalaPlanilha: false,
      isRelConsPorProf: false,
      isRelDetPorProf: false,
      isRelPreVReal: false,
      diasSemana: [
        { text: 'D', codigo: 0, value: 'dom', selecionado: true },
        { text: 'S', codigo: 1, value: 'seg', selecionado: true },
        { text: 'T', codigo: 2, value: 'ter', selecionado: true },
        { text: 'Q', codigo: 3, value: 'qua', selecionado: true },
        { text: 'Q', codigo: 4, value: 'qui', selecionado: true },
        { text: 'S', codigo: 5, value: 'sex', selecionado: true },
        { text: 'S', codigo: 6, value: 'sab', selecionado: true },
      ],
      modelo: null,
      optionsFiltroModelo: [
        { value: null, text: 'Selecione o modelo' },        
      ],
    }
  },
  mounted() {

    const token = String(localStorage.getItem('authorization')).replaceAll('"', '')

    if (token === null || token === undefined || token === '' || token === 'null') {
      this.$route.router.go('/login');
    } else {
      /*axios.get(BASE_CONSTANTS.urlBase + 'oauth/check_token/?token=' + token).then(resposta => {
        this.carregaDados()
      }).catch(error => {
        if (error === false) {
          this.$router.push('/login');
        }
      })*/
    }

    this.dadosRelatorio = []
    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })

    const dataAtual = new Date()
    const anoAtual = dataAtual.getFullYear()
    this.anos = [anoAtual - 1, anoAtual]

    this.mes = dataAtual.getMonth() + 1
    this.ano = anoAtual

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd")
    this.dtFim = dateToStr(lastDay, "yyyy-MM-dd")

    this.exibirValorVenda = this.hasPermission('RELATORIO_FINANCEIRO', 'PRECO_VENDA', 'E')
    this.exibirValorCompra = this.hasPermission('RELATORIO_FINANCEIRO', 'PRECO_COMPRA', 'E')

    this.isRelEscalaPdf = this.hasPermissionWith('RELATORIO', 'ESCALA_EM_PDF', 'E')
    this.isRelEscalaPlanilha = this.hasPermissionWith('RELATORIO', 'ESCALA_EM_PLANILHA', 'E')
    this.isRelConsPorProf = this.hasPermissionWith('RELATORIO', 'CONSOLIDADO_POR_PROFISSIONAL', 'E')
    this.isRelDetPorProf = this.hasPermissionWith('RELATORIO', 'DETALHADO_POR_PROFISSIONAL', 'E')
    this.isRelPrevReal = this.hasPermissionWith('RELATORIO', 'PREVISTO_X_REALIZADO', 'E')

    if (this.isRelEscalaPdf) this.optionsFiltroModelo.push({ value: 'escala_pdf', text: 'Escala em PDF' })
    if (this.isRelEscalaPlanilha) this.optionsFiltroModelo.push({ value: 'escala_excel', text: 'Escala em Excel' })
    if (this.isRelConsPorProf) this.optionsFiltroModelo.push({ value: 'consolidado', text: 'Consolidado' })
    if (this.isRelDetPorProf) this.optionsFiltroModelo.push({ value: 'detalhado', text: 'Detalhado' })
    if (this.isRelPrevReal) this.optionsFiltroModelo.push({ value: 'previsto_realizado', text: 'Previsto x Realizado' })

  },

  methods: {
    goMesAnterior() {
      const dataAtual = new Date(this.dtInicio + "T00:00:00")
      dataAtual.setMonth(dataAtual.getMonth() - 1);
      var firstDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1)
      var lastDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0)
      this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd")
      this.dtFim = dateToStr(lastDay, "yyyy-MM-dd")
    },
    goMesSeguinte() {
      const dataAtual = new Date(this.dtInicio + "T00:00:00")
      dataAtual.setMonth(dataAtual.getMonth() + 1);
      var firstDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1)
      var lastDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0)
      this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd")
      this.dtFim = dateToStr(lastDay, "yyyy-MM-dd")
    },
    filterBy(option, label, search) {
      const { filterBy } = appUtils(this)
      return filterBy(option, label, search)
    },
    atualizaAgendasSelecionada() {
      this.idsAgendaSelecionada = this.itens.filter(function (elem) {
        return elem.seleciona === 'S'
      }).map(elem => elem.id);
    },
    localAlterado() {
      this.itensProfissionais.length = 0;
      this.itensTipo.length = 0;
    },
    buscaLocais() {

      if (this.itensLocal.length == 0) {

        const { toast, ativarShow, desativarShow } = appUtils(this)

        let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

        let dadosConsulta = dadosConsulta = '{ "idUsuario": ' + dadosUsuario.idPessoa + '}'

        AgendaService.listaLocaisFilter(dadosConsulta).then(resposta => {
          this.itensLocal = resposta.data.obj

        }).catch(error => {

        })
      }

    },
    buscaProfissionais() {

      if (this.itensProfissionais.length == 0) {

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("buscaProfissionais");

        let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

        let idsLocal = [];
        if (this.itemLocal != null) {
          this.itemLocal.forEach((item) => {
            idsLocal.push(item.id);
          });
        }

        let dadosConsulta = {
          "idUsuario": dadosUsuario.idPessoa,
          "idsLocal": idsLocal,
          "idEmpresa": dadosUsuario.grupo.empresa.id
        }

        AgendaService.listaProfissionaisFilter(this.http, dadosConsulta)
          .then((resposta) => {
            this.itensProfissionais = resposta.data.obj;
            desativarShow("buscaProfissionais");
          })
          .catch(() => {
            desativarShow("buscaProfissionais");
          });
      }

    },
    buscaTipos() {

      if (this.itensTipo.length == 0) {

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("buscaTipos");

        let idsLocal = [];
        if (this.itemLocal != null) {
          this.itemLocal.forEach((item) => {
            idsLocal.push(item.id);
          });
        }

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
        let dadosConsulta =
          '{ "idUsuario": ' + dadosUsuario.idPessoa +
          ',"idsLocal": [' + idsLocal + "] }";

        AgendaService.listaTiposFilter(dadosConsulta)
          .then((resposta) => {
            this.itensTipo = resposta.data.obj;
            desativarShow("buscaTipos");
          })
          .catch((error) => {
            desativarShow("buscaTipos");
          });
      }

    },
    buscaSituacoes() {

      if (this.itensSituacao.length == 0) {

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("buscaSituacoes")

        let idsLocal = [];
        if (this.itemLocal != null) {
          this.itemLocal.forEach((item) => {
            idsLocal.push(item.id);
          });
        }

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
        let dadosConsulta = {
          "idUsuario": dadosUsuario.idPessoa,
          "idsLocal": idsLocal,
          "acima": true,
          "abaixo": true
        }

        AgendaService.recuperaSituacoes(dadosConsulta)
          .then((resposta) => {
            this.itensSituacao = resposta.data.obj;
            desativarShow("buscaSituacoes");
          })
          .catch((error) => {
            desativarShow("buscaSituacoes");
          });
      }
    },
    buscar() {

      let mod = this.modelo ? this.modelo : ''      
        if (mod === 'consolidado') {
          this.confirmaRelatorioFinanceiro()
        } else if (mod === 'detalhado') {
          this.confirmaRelatorioDetalhado()          
        } else {
          this.gerar()    
        }
    },
    gerar(op) {

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("gerar")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dias = this.diasSemana.filter((d) => { return d.selecionado }).map(d => ({ id: d.codigo, value: d.value }))

      let idsLocal = [];
      let idsProfissional = [];
      let idsTipo = [];
      let idsSituacao = [];
      if (this.itemLocal != null) {
        this.itemLocal.forEach((item) => {
          idsLocal.push(item.id)
        });
      }
      if (this.itemProfissional != null) {
        this.itemProfissional.forEach((item) => {
          idsProfissional.push(item.id)
        });
      }
      if (this.itemTipo != null) {
        this.itemTipo.forEach((item) => {
          idsTipo.push(item.id)
        });
      }
      if (this.itemSituacao != null) {
        this.itemSituacao.forEach((item) => {
          idsSituacao.push(item.id)
        });
      }

      let duracaoMinuto = this.convertHoraEmMinutos(this.duracao)

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": dadosUsuario.grupo.empresa.id,
        "dtInicio": this.dtInicio,
        "dtFim": this.dtFim,
        "horaInicio": this.horaInicio,
        "horaFim": this.horaFim,
        "duracao": duracaoMinuto,
        "idGrupoUsuario": dadosUsuario.grupo.id,
        "idsLocal": idsLocal,
        "idsProfissional": idsProfissional,
        "idsTipo": idsTipo,
        "idsSituacao": idsSituacao,
        "diasSemana": dias,
        "modelo": this.modelo,
        "comPrecoVenda": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorVenda' }).length == 1 ? 'S' : 'N',
        "comPrecoCompra": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorCompra' }).length == 1 ? 'S' : 'N',
        "comTipoServico": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'tipoServico' }).length == 1 ? 'S' : 'N',
        "comPrecoUnitario": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorUnitario' }).length == 1 ? 'S' : 'N'
      }

      AgendaService.geraRelatorioGeral(dadosConsulta)
        .then(resposta => {
          let retorno = resposta.data;

          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj.conteudo

            if (retorno.obj.formatoConteudo === 'pdf') {
              funcao.methods.downloadPdf(base64)
            } else if (retorno.obj.formatoConteudo === 'xlsx') {
              funcao.methods.downloadExcel(base64)
            }

          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }

          desativarShow("gerar")
        }).catch(error => {
          desativarShow("gerar")
        })

    },
    convertHoraEmMinutos(data) {
      if (data !== null && data !== undefined) {
        let f0 = data.split(":");
        let minutos = Number(f0[0]) * 60 + Number(f0[1]);
        return minutos;
      }
    },
    confirmaRelatorioFinanceiro() {
      let colunas = []
      if (this.exibirValorVenda) colunas.push({ text: 'Valor de venda', value: 'valorVenda' })
      if (this.exibirValorCompra) colunas.push({ text: 'Valor de compra', value: 'valorCompra' })
      colunas.push({ text: 'Tipo de serviço', value: 'tipoServico' })
      if (this.exibirValorVenda || this.exibirValorCompra) colunas.push({ text: 'Valor unitário', value: 'valorUnitario' })

      this.$refs.confRelFinConsolidado.show(colunas)
    },
    confirmaRelatorioDetalhado() {
      let colunas = []
      if (this.exibirValorVenda) colunas.push({ text: 'Valor de venda', value: 'valorVenda' })
      if (this.exibirValorCompra) colunas.push({ text: 'Valor de compra', value: 'valorCompra' })

      if (colunas.length > 0) {
        this.$refs.confRelFinDetalhado.show(colunas)
      } else {
        this.gerar()
      }
    },
    exportarExcelConsolidado(op) {
      this.gerar(op)

    },
    exportarExcelDetalhado(op) {
      this.gerar(op)
    },
    hasPermission(menu, permissao, nivel) {
      return this.$store.getters['auth/hasPermissionWith'](menu, permissao, nivel)
    },
    hasPermissionWith(menu, permissao, nivel) {
      return this.$store.getters['auth/hasPermissionWith'](menu, permissao, nivel)
    },
  },
  computed: {
    rows() {
      return this.dadosRelatorio.itens === undefined ? 0 : this.dadosRelatorio.itens.length
    },
    existeAgendaSelecionada() {
      return this.itens.filter(function (elem) {
        return elem.seleciona === 'S'
      }).length > 0
    }

  }
}

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format.replace('yyyy', date.getFullYear())
      .replace('MM', (date.getMonth() + 1).toString().padStart(2, '0'))
      .replace('dd', date.getDate().toString().padStart(2, '0'))
      .replace('HH', date.getHours().toString().padStart(2, '0'))
      .replace('mm', date.getMinutes().toString().padStart(2, '0'))
      .replace('ss', date.getSeconds().toString().padStart(2, '0'));
    return strDate;
  }
}

</script>
<style lang="scss">
.manutencao-agenda {

  .c-form {
    padding-bottom: 60px;
  }

  tbody {
    max-height: 300px;
  }

  .grid-td {
    width: 100%;
  }

  .grid-td .grid-line {
    padding-left: 10px;
    padding-top: 1px;
    /*border-left: 5px solid red !important;*/
    width: 100%;
    height: 50px;

  }

  .grid-line .div-grid-line {
    width: 100%;
  }

  .div-grid-line .div-grid-line-cor {
    width: 38px;
  }

  .cor-fim-semana {
    color: red;
  }

  .fonte-local {
    font-size: 0.7rem;
  }

}
</style>
